import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Preset_LoginPageOfCarousel } from '../heineken_template/_preset/preset_LoginPageOfCarousel'
import { asia71178_initStrategies } from './asia71178_initStrategies'
import { asia71178_initStyling } from './asia71178_initStyling'
import { Asia71178_SidePane1 } from './asia71178_SidePane1'
import { Asia71178_SidePane2 } from './asia71178_SidePane2'
import { Asia71178_Topbar } from './asia71178_Topbar'
import { Asia71178_OptionQuote } from './option/asia71178_optionQuote'

export const asia71178_init = {
  global(templateProps: TemplateProps) {
    asia71178_initStyling(templateProps)
    asia71178_initStrategies()

    useThemeStore.setState({ theme: 'light' })
    store.charting.setThemeMode('light')

    templateProps.layout.Charting = undefined
    templateProps.layout.Row1 = Asia71178_Topbar
  },

  indexPage(templateProps: TemplateProps) {
    asia71178_initStyling(templateProps)
    asia71178_initStrategies()
    useThemeStore.setState({ theme: 'light' })
    store.charting.setThemeMode('light')

    templateProps.layout.login = (
      <Preset_LoginPageOfCarousel
        resources={[{ image: [true, '/asia71178_init/loginView.jpeg'] }]}
      />
    )

    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

    store.charting.widgetOptions = {
      ...store.charting.widgetOptions,
      symbol: 'TX-1',
      interval: 'D',
      enableVolumeIndicator: false,
      disabledHeaderWidget: false,
      disabledLeftToolbar: false,
      disabledTimeframesToolbar: true,
      disabledHeaderChartType: true,
      disabledHeaderCompare: true,
      disabledGoToDate: true,
      disabledHeaderSaveload: true,
      overrides: store.charting.lightOverrides,
    }

    templateProps.layout.cssset = css`
      grid-template-rows: 48px 1fr;
      grid-template-columns: 336px 1fr 336px;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Chart Drawer2'
        'Drawer1 Chart Drawer2';

      ${createIPadCss(css`
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart';
      `)}
    `

    templateProps.layout.Row1 = Asia71178_Topbar
    templateProps.layout.Drawer1 = Asia71178_SidePane2
    templateProps.layout.Drawer2 = Asia71178_SidePane1
  },

  ['option/index.page'](templateProps: TemplateProps) {
    asia71178_initStyling(templateProps)
    asia71178_initStrategies()

    useThemeStore.setState({ theme: 'light' })
    store.charting.setThemeMode('light')

    templateProps.layout.Row1 = Asia71178_Topbar

    store.charting.widgetOptions = {
      ...store.charting.widgetOptions,
      symbol: 'TX-1',
      interval: '5',
      enableVolumeIndicator: true,
      disabledHeaderWidget: false,
      disabledLeftToolbar: true,
      disabledTimeframesToolbar: true,
      disabledHeaderChartType: true,
      disabledHeaderCompare: true,
      disabledGoToDate: true,
      disabledHeaderSaveload: true,
      disabledSymbolSearch: true,
      overrides: store.charting.lightOverrides,
    }

    templateProps.layout.cssset = css`
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 336px 1fr 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Drawer1 Chart Chart'
        'Drawer1 Col2 Col2';

      ${createIPadCss(css`
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Col2 Col2 Col2';
      `)}

      ${createMobileCss2(css`
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Col2 Col2 Col2';
      `)}
    `

    templateProps.layout.Row1 = Asia71178_Topbar
    templateProps.layout.Drawer1 = Asia71178_SidePane2
    templateProps.layout.Col2 = Asia71178_OptionQuote
  },
}
